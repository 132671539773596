/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 595px) {
    body {
      // border-right: 4px solid red;
    }
}
  
/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) {
    body {
      // border-right: 4px solid orange;
    }
}
  
/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
    body {
      // border-right: 4px solid yellow;
    }
}
  
/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
    body {
      // border-right: 4px solid green;
    }
}
/* Extra big screen devices (1024px and above) */
@media only screen and (min-width: 1024) {
    body {
      // border-right: 4px solid blue;
    }
}
  
/* Extra big screen devices (1280px and above) */
@media only screen and (min-width: 1280px) {
    body {
      // border-right: 4px solid violet;
    }

    .login-container {
        .card {
            width: 70vw;
        }
    }
}
  
/* Extra big screen devices (1360px and above) */
@media only screen and (min-width: 1360px) {
    body {
      // border-right: 4px solid red;
    }

    .login-container {
        .card {
            width: 45vw;
        }
    }
}
  
/* Extra big screen devices (1440px and above) */
@media only screen and (min-width: 1440px) {
    body {
      // border-right: 4px solid fuchsia;
    }
}
  
/* Extra big screen devices (1600px and above) */
@media only screen and (min-width: 1600px) {
    body {
      // border-right: 4px solid gray;
    }
}

.filter-inputs { 
  width: 100%;
  gap: 1rem;  
  display: flex;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-inline: 15px;
}

@media (max-width: 767px) {
  .filter-inputs {
      width: 100%;
      display: flex;
      flex-direction: column; 
  }
  .outpatient-tabs-wrapper {
      display: flex;
      flex-direction: column;
  }
  .outpatient-tabs {
      display: flex;
      flex-direction: row;
  }
  .tabs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
  }
  .outpatient-tabs  button{
      width: 100%;
      font-size: 14px;
  }
  .outpatient-add-appt button{
      width: 100%;
  }
}

@media (min-width: 1024px) {
  .filter-inputs-section1 {
      display: flex;
      flex-direction: row;
      gap: 16px;
  }
  .filter-inputs-section2 {
      display: flex;
      flex-direction: row;
      gap: 16px;
  }
  .tabs-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
} 

@media (min-width: 768px) {
  .tabs-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
} 

.table-responsive-outpatient {
  overflow-x: auto;
  margin-bottom: 20px;
  width: 100%;
}
.appt-date {
  white-space: nowrap;
}
.action-btns-outpatient button{
  white-space: nowrap;
}

.visible-btns {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.patient-worklist-table thead th {
  text-align: left;
  font-weight: bold;
  border-bottom: 0.5px solid #000;
  background-color: #fff;
  vertical-align: bottom;
  white-space: nowrap;
}

.th-content {
  display: flex;
  align-items: center;
  gap: 6px;
}

.patient-worklist-table tr td {
  text-align: left;
  border: none !important;
  // vertical-align: middle;
  padding-inline: 20px;
}

@media (max-width: 768px) {
  .clinical-doc-tabs.btn-group {
      display: grid !important; 
      grid-template-columns: 1fr 1fr !important;
  }
}

.med-hist-table-wrapper {
  overflow: hidden;
}
.med-hist-table-scroller {
  overflow-x: auto;
}

:host ::ng-deep {
  .p-dialog {
    width: 600px;
    
    @media (max-width: 768px) {
      width: 320px !important;
      
      .p-dialog-content {
        padding: 1rem;
      }
    }
  }
}
:host ::ng-deep .ngx-pagination {
  margin-left: -20px;
}